<template>
  <v-sheet class="customer" id="customer" style="height: calc(100vh - 140px)">
    <v-row>
      <!-- <pre>{{ customer }}</pre> -->
      <v-col md="12">
        <v-row>
          <v-col md="6" class="my-auto py-0">
            <h1 class="custom-header-blue-text m-0">{{ pageTitle() }}</h1>
          </v-col>
          <v-col md="6" class="text-right py-0">
            <v-btn
              :disabled="pageLoading || !formValid"
              :loading="pageLoading"
              class="white--text mr-2"
              depressed
              color="blue darken-4"
              tile
              v-on:click="updateOrCreate"
            >
              Save
            </v-btn>
            <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col md="10">
                <v-form
                  ref="customerForm"
                  v-model.trim="formValid"
                  lazy-validation
                  v-on:submit.stop.prevent="updateOrCreate"
                >
                  <v-row>
                    
                    <!-- <v-col md="3" class="my-auto py-0">
                      <label for="first-name" class="btx-label mt-2 required">Type</label>
                    </v-col> -->
                    <v-col md="3" class="my-auto py-0">
                      <label for="first-name" class="btx-label mt-2 required">Type</label>
                    </v-col>
                    
                    
                    <v-col md="9" class="py-0">
                      <v-layout>
                        <v-radio-group
                  id="template_type"
                  hide-details
                  dense
                  mandatory
                  row
                  style="width: 100%; padding: 0px 8px"
                  class="d-flex"
                  v-model="customer.type"
                >
                    <v-col md="3">
                      <v-radio
                        value="gold"
                        class="font-level-2-bold px-4 p-2 mr-0 d-flex justify-space-between"
                        label="SMS"
                        :class="customer.type == 'gold' ? 'orange lighten-4' : 'grey lighten-5'"
                        :color="customer.type == 'gold' ? 'orange ' : ''"
                        text-color="white"
                        icon="mdi-message-outline"
                        x-large
                        style="background-color: #f2d8b8; width: auto"
                        :style="[
                          customer.type == 'gold'
                            ? { border: ' 1px solid #e08310 !important' }
                            : { border: '1px solid #ECEFF1 !important' },
                        ]"
                      >
                        <template v-slot:label>
                          <div
                            class="d-flex justify-space-between align-center"
                            style="width: 100%"
                          >
                            <span class="font-level-3-bold"> GOLD </span>
                           
                          </div>
                        </template>
                      </v-radio>
                      </v-col>
                      <v-col md="3">
                      <v-radio
                        value="platinum"
                        class="font-level-2-bold px-4 p-2 mr-0 d-flex justify-space-between"
                        label="SMS"
                        :class="customer.type == 'platinum' ? 'orange lighten-4' : 'grey lighten-5'"
                        :color="customer.type == 'platinum' ? 'orange ' : ''"
                        text-color="white"
                        icon="mdi-message-outline"
                        x-large
                        style="background-color: #f2d8b8; width: auto"
                        :style="[
                          customer.type == 'platinum'
                            ? { border: ' 1px solid #e08310 !important' }
                            : { border: '1px solid #ECEFF1 !important' },
                        ]"
                      >
                        <template v-slot:label>
                          <div
                            class="d-flex justify-space-between align-center"
                            style="width: 100%"
                          >
                            <span class="font-level-3-bold"> PLATINUM </span>
                            
                          </div>
                        </template>
                      </v-radio>
                    </v-col>
                    </v-radio-group>

         
                       
                        
                      </v-layout>
                    </v-col>
                    <v-col md="3" class="my-auto py-0">
                      <label for="email" class="btx-label mt-2">Name</label>
                    </v-col>
                    <v-col md="9" class="py-0">
                      <v-layout>
                        <v-flex class="max-width-100px">
                          <SelectInput
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            :items.sync="listTitle"
                            id="salutation"
                            v-model="customer.title"
                            placeholder="Title"
                          ></SelectInput>
                        </v-flex>
                        <v-flex class="mx-2">
                          <TextInput
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="first-name"
                            placeholder="First Name"
                            v-model="customer.first_name"
                            :rules="[vrules.required(customer.first_name, 'First Name')]"
                            :class="{ required: !customer.first_name }"
                          ></TextInput>
                        </v-flex>
                        <v-flex>
                          <TextInput
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="last-name"
                            v-model="customer.last_name"
                            placeholder="Last Name"
                          ></TextInput>
                        </v-flex>
                      </v-layout>
                    </v-col>
                    <v-col md="3" class="my-auto py-0">
                      <label for="date-of-birth" class="btx-label mt-2">Date of Birth</label>
                    </v-col>
                    <v-col md="9" class="py-0">
                      <DatePicker
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="date-of-birth"
                        v-model="customer.date_of_birth"
                     

                        placeholder="Date of Birth"
                      ></DatePicker>
                    </v-col>
                     <v-col md="3" class="my-auto py-0">
                      <label for="gender" class="btx-label mt-2">Gender</label>
                    </v-col>
                    <v-col md="9" class="py-0">
                      <SelectInput
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        :items.sync="genderList"
                        id="gender"
                        v-model="customer.gender"
                        :rules="[vrules.required(customer.gender, 'gender')]"
                            :class="{ required: !customer.gender }"
                        placeholder="gender"
                      ></SelectInput>
                    </v-col>
                    <v-col md="3" class="my-auto py-0">
                      <label for="gender" class="btx-label mt-2">Email</label>
                    </v-col>
                    <v-col md="9" class="py-0">
                      <EmailInput
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="email"
                  v-model="customer.email"
                  placeholder="Email"
                ></EmailInput>
                    </v-col>
                    <v-col md="3" class="my-auto py-0">
                      <label for="gender" class="btx-label mt-2">Date of Joining</label>
                    </v-col>
                    <v-col md="9" class="py-0">
                   
                      <DatePicker
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="date-of-birth"
                        
                        :rules="[vrules.required(customer.joining_date, 'joining_date')]"
                            :customClass="{ required: !customer.joining_date }"
                        v-model="customer.joining_date"
                        placeholder="Date of Joining"
                      ></DatePicker>
                    </v-col>
                    <template v-if="customer.type != 'gold'">
                      <v-col md="3" class="my-auto py-0" >
                      <label for="gender" class="btx-label mt-2">Expiry Date</label>
                    </v-col>
                    <v-col md="9" class="py-0">
                      <DatePicker
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        :rules="[vrules.required(customer.expiry_date, 'expiry_date')]"
                            :customClass="{ required: !customer.expiry_date }"
                        id="date-of-birth"
                        v-model="customer.expiry_date"
                        placeholder="Date of Birth"
                      ></DatePicker>
                    </v-col>
                    </template>
                   
                    <v-col md="3" class="my-auto py-0">
                      <label for="phone-number" class="btx-label mt-2 required">Phone Number</label>
                    </v-col>
                    <v-col md="9" class="py-0">
                      <!-- <PhoneTemplate
                          hide-details
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          :rules="[vrules.required(customer.phone_number, 'Phone Number')]"
                          :class="{ required: !customer.phone_number }"
                          id="phone-number"
                          v-model="customer.phone_number"
                          placeholder="Phone Number"
                        ></PhoneTemplate> -->
                      <PhoneInput
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        :rules="[vrules.required(customer.phone_number, 'Phone Number')]"
                        :class="{ required: !customer.phone_number }"
                        id="phone-number"
                        v-model="customer.phone_number"
                        placeholder="Phone Number"
                      ></PhoneInput>
                    </v-col>
                    <v-col md="3" class="my-auto py-0">
                      <label for="address-line-1" class="btx-label mt-2">Address Line 1</label>
                    </v-col>
                    <v-col md="9" class="py-0">
                      <TextInput
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="address-line-1"
                        v-model="customer.address_line_1"
                        placeholder="Address Line 1"
                      ></TextInput>
                    </v-col>
                    <v-col md="3" class="my-auto py-0">
                      <label for="address-line-2" class="btx-label mt-2">Address Line 2</label>
                    </v-col>
                    <v-col md="9" class="py-0">
                      <TextInput
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="address-line-2"
                        v-model="customer.address_line_2"
                        placeholder="Address Line 2"
                      ></TextInput>
                    </v-col>
                    <v-col md="3" class="my-auto py-0">
                      <label for="unit-number" class="btx-label mt-2">Unit #</label>
                    </v-col>
                    <v-col md="9" class="py-0">
                      <TextInput
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="unit-number"
                        v-model="customer.unit_number"
                        placeholder="Unit #"
                      ></TextInput>
                    </v-col>
                    <v-col md="3" class="my-auto py-0">
                      <label for="postal-code" class="btx-label mt-2">Postal Code</label>
                    </v-col>
                    <v-col md="9" class="py-0">
                      <PostalCode
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="postal-code"
                        v-model="customer.postal_code"
                        placeholder="Postal Code"
                      ></PostalCode>
                    </v-col>
                    <v-col md="3" class="my-auto py-0">
                      <label for="country" class="btx-label mt-2">Country</label>
                    </v-col>
                    <v-col md="9" class="py-0">
                      <SelectInput
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        :items.sync="listCountry"
                        id="country"
                        v-model="customer.country"
                        placeholder="Country"
                      ></SelectInput>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- <v-col md="10">
        <v-form
          ref="customerForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="updateOrCreate">
          <v-container fluid>
            <v-row>
              <v-col md="3" class="my-auto py-1">
                <label for="first-name" class="btx-label required">Name</label>
              </v-col>
              <v-col md="9" class="py-1">
                <v-layout>
                  <v-flex class="max-width-100px">
                    <SelectInput
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      :items.sync="listTitle"
                      id="salutation"
                      v-model="customer.title"
                      placeholder="Title"
                    ></SelectInput>
                  </v-flex>
                  <v-flex class="mx-2">
                    <TextInput
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      id="first-name"
                      placeholder="First Name"
                      v-model="customer.first_name"
                      :rules="[vrules.required(customer.first_name, 'First Name')]"
                      :class="{ required: !customer.first_name }"
                    ></TextInput>
                  </v-flex>
                  <v-flex>
                    <TextInput
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      id="last-name"
                      v-model="customer.last_name"
                      placeholder="Last Name"
                    ></TextInput>
                  </v-flex>
                </v-layout>
              </v-col>
              
              <v-col md="3" class="my-auto py-1">
                <label for="email" class="btx-label">Email</label>
              </v-col>
              <v-col md="9" class="py-1">
                <EmailInput
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="email"
                  v-model="customer.email"
                  placeholder="Email"
                ></EmailInput>
              </v-col>
              <v-col md="3" class="my-auto py-1">
                <label for="date-of-birth" class="btx-label">Date of Birth</label>
              </v-col>
              <v-col md="9" class="py-1">
                <DatePicker
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="date-of-birth"
                  v-model="customer.date_of_birth"
                  placeholder="Date of Birth"
                ></DatePicker>
              </v-col>
              <v-col md="3" class="my-auto py-1">
                <label for="gender" class="btx-label">Gender</label>
              </v-col>
              <v-col md="9" class="py-1">
                <SelectInput
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  :items.sync="genderList"
                  id="gender"
                  v-model="customer.gender"
                  placeholder="gender"
                ></SelectInput>
              </v-col>
              <v-col md="3" class="my-auto py-1">
                <label for="phone-number" class="btx-label required">Phone Number</label>
              </v-col>
              <v-col md="9" class="py-1">
                <PhoneTemplate
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  :rules="[vrules.required(customer.phone_number, 'Phone Number')]"
                  :class="{ required: !customer.phone_number }"
                  id="phone-number"
                  v-model="customer.phone_number"
                  placeholder="Phone Number"
                ></PhoneInput>
              </v-col>
              <v-col md="3" class="my-auto py-1">
                <label for="address-line-1" class="btx-label">Address Line 1</label>
              </v-col>
              <v-col md="9" class="py-1">
                <TextInput
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="address-line-1"
                  v-model="customer.address_line_1"
                  placeholder="Address Line 1"
                ></TextInput>
              </v-col>
              <v-col md="3" class="my-auto py-1">
                <label for="address-line-2" class="btx-label">Address Line 2</label>
              </v-col>
              <v-col md="9" class="py-1">
                <TextInput
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="address-line-2"
                  v-model="customer.address_line_2"
                  placeholder="Address Line 2"
                ></TextInput>
              </v-col>
              <v-col md="3" class="my-auto py-1">
                <label for="unit-number" class="btx-label">Unit #</label>
              </v-col>
              <v-col md="9" class="py-1">
                <TextInput
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="unit-number"
                  v-model="customer.unit_number"
                  placeholder="Unit #"
                ></TextInput>
              </v-col>
              <v-col md="3" class="my-auto py-1">
                <label for="postal-code" class="btx-label">Postal Code</label>
              </v-col>
              <v-col md="9" class="py-1">
                <PostalCode
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="postal-code"
                  v-model="customer.postal_code"
                  placeholder="Postal Code"
                ></PostalCode>
              </v-col>
              <v-col md="3" class="my-auto py-1">
                <label for="country" class="btx-label">Country</label>
              </v-col>
              <v-col md="9" class="py-1">
                <SelectInput
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  :items.sync="listCountry"
                  id="country"
                  v-model="customer.country"
                  placeholder="Country"
                ></SelectInput>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col> -->
    </v-row>
  </v-sheet>
</template>

<script>
import { toSafeInteger } from "lodash";
import TextInput from "@/view/components/TextInput";
import EmailInput from "@/view/components/EmailInput";
import SelectInput from "@/view/components/SelectInput";
import PostalCode from "@/view/components/PostalCode";
// import PhoneTemplate from "@/view/components/Phone";
import PhoneInput from "@/view/components/PhoneInput";
import DatePicker from "@/view/components/DatePicker";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CREATE_CUSTOMER, UPDATE_CUSTOMER, GET_CUSTOMER } from "@/core/lib/customer.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import MomentJS from "moment-timezone";

export default {
  name: "customer-create",
  title: "Create Customer",
  data() {
    return {
      pageLoading: false,
      broadcast: {
        type: "whatsapp",
      },
      formValid: true,
      customerId: null,
      listCountry: [
        {
          text: "Singapore",
          value: "singapore",
        },
        {
          text: "Malaysia",
          value: "malaysia",
        },
        {
          text: "Australia",
          value: "australia",
        },
      ],
      genderList: [
        {
          text: "Male",
          value: "male",
        },
        {
          text: "Female",
          value: "female",
        },
        {
          text: "Other",
          value: "other",
        },
      ],
      customer: {
        title: null,
        type:'gold',
        first_name: null,
        last_name: null,
        gender: null,
        email: null,
        date_of_birth: null,
        phone_number: null,
        address_line_1: null,
        expiry_date:null,
        joining_date:null,
        address_line_2: null,
        unit_number: null,
        postal_code: null,
        country: null,
      },
    };
  },
  components: {
    TextInput,
     EmailInput,
    SelectInput,
    DatePicker,
    PostalCode,
    // PhoneTemplate,
    PhoneInput,
  },
//     computed: {
//     SelectDate: {
//       selectDate(){
//       alert(1)
// this.test=this?.customer?.joining_date;
// this.customer.expiry_date=MomentJS(this.test,'YYYY-MM-DD').add(1, 'y').format('YYYY-MM-DD')
// console.log(this.customer.expiry_date,'ddate') 
// // MomentJS().format("YYYY-MM-DD")

//     }
//     },
//   },
watch:{
  "customer.joining_date":{
      handler(param){
        this.customer['expiry_date'] = MomentJS(param,'YYYY-MM-DD').add(1, 'y').format('YYYY-MM-DD');
      }
    },

},
  methods: {

    pageTitle() {
      if (this.customerId) {
        return "Update Customer";
      }
      return "Create Customer";
    },
    async updateOrCreate() {
      const _this = this;

      if (!_this.$refs.customerForm.validate()) {
        return false;
      }

      try {
        _this.pageLoading = true;
        console.log(_this.customer,'cistomer')
        if (_this.customerId) {
          const customer = await UPDATE_CUSTOMER(_this.customerId, _this.customer);
          _this.$router.replace({
            name: "customer-detail",
            params: { id: customer.id },
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Customer Updated Successfully." },
          ]);
        } else {
          const customer = await CREATE_CUSTOMER(_this.customer);
          _this.$router.replace({
            name: "customer-detail",
            params: { id: customer.id },
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Customer Created Successfully." },
          ]);
        }
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
    async getCustomer() {
      const customer = await GET_CUSTOMER(this.customerId);
      this.customer = {
        title: customer.customer.title,
        type: customer.customer.type,
        joining_date:customer.customer.joining_date,
        expiry_date:customer.customer.expiry_date,

        first_name: customer.customer.first_name,
        last_name: customer.customer.last_name,
        email: customer.customer.email,
        date_of_birth: customer.customer.date_of_birth,
        gender: customer.customer.gender,
        phone_number: customer.customer.phone_number,
        address_line_1: customer.customer.address_line_1,
        address_line_2: customer.customer.address_line_2,
        unit_number: customer.customer.unit_number,
        postal_code: customer.customer.postal_code,
        country: customer.customer.country,
      };
      this.$store.dispatch(SET_BREADCRUMB, [
        { text: "Customer", disabled: true },
        { text: "Update", disabled: true },
        { text: customer.barcode, disabled: true },
      ]);
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Customer", disabled: true },
      { text: "Create", disabled: true },
    ]);

    const { name, params } = this.$route;
    if (name === "customer-update") {
      const { id } = params;
      if (id) {
        this.customerId = toSafeInteger(id);
        this.getCustomer();
      } else {
        this.goBack();
      }
    }
  },
};
</script>
